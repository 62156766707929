"use client";

import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@/contexts/common/AuthContextProvider";
import { AgencyRestrictions, AgencyStatusResponse } from "@/types/agencies";
import { apiLive } from "@/utils/common/api";
import { GetRestrictions } from "@/utils/common/restrictions";

type restrictedText = {
  type:
    | "campaign"
    | "sequences"
    | "redialingRules"
    | "contacts"
    | "contactLists"
    | "testCases"
    | "filesUpload";
  key: "maxTotal" | "maxUpload" | "maxConcurrency" | "maxContacts";
};

type RestrictionsProps = {
  restrictions: AgencyRestrictions;
  getRestrictionValue: (restrictedText: restrictedText) => number;
  getRestrictedText: (
    restrictedText: restrictedText,
    currentValue: number
  ) => string;
};

export const RestrictionsContext = createContext<RestrictionsProps>({
  restrictions: GetRestrictions(null),
  getRestrictionValue: () => 0,
  getRestrictedText: () => "",
});

export const useRestrictions = () => useContext(RestrictionsContext)!;

let userIdLoaded = "";

const useRestrictionsFunction = (): RestrictionsProps => {
  const { getToken, user } = useAuthContext();
  const { t } = useTranslation("restrictions");

  const [restrictions, setRestrictions] = useState<AgencyRestrictions>(
    GetRestrictions(null)
  );

  const fetchRestrictions = async () => {
    const response = await apiLive<AgencyStatusResponse>(
      getToken,
      `/api/settings/agency/status`
    );

    if (response && response.restrictions) {
      setRestrictions(response.restrictions);
    }
  };

  useEffect(() => {
    if (user) {
      if (userIdLoaded === user.id) {
        return;
      }

      userIdLoaded = user.id;
      fetchRestrictions();
    } else {
      setRestrictions(GetRestrictions(null));
    }
  }, [user]);

  const getRestrictionValue = (restrictedText: restrictedText): number => {
    switch (restrictedText.type) {
      case "campaign":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.campaign.maxTotal;
          case "maxConcurrency":
            return restrictions.campaign.maxConcurrency;
          case "maxContacts":
            return restrictions.campaign.maxContacts;
          default:
            return 0;
        }
      case "sequences":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.sequences.maxTotal;
          default:
            return 0;
        }
      case "redialingRules":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.redialingRules.maxTotal;
          default:
            return 0;
        }
      case "contacts":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.contacts.maxTotal;
          case "maxUpload":
            return restrictions.contacts.maxUpload;
          default:
            return 0;
        }
      case "contactLists":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.contactLists.maxTotal;
          default:
            return 0;
        }
      case "testCases":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.testCases.maxTotal || 0;
          default:
            return 0;
        }
      case "filesUpload":
        switch (restrictedText.key) {
          case "maxTotal":
            return restrictions.filesUpload.maxTotal || 0;
          default:
            return 0;
        }
    }
  };

  const getRestrictedText = (
    restrictedText: restrictedText,
    currentValue: number
  ): string => {
    const value = getRestrictionValue(restrictedText);

    if (value <= -1 || value > currentValue) {
      return "";
    }

    return t(`${restrictedText.type}.${restrictedText.key}`, {
      count: value,
    });
  };

  return {
    restrictions,
    getRestrictionValue,
    getRestrictedText,
  };
};

export const RestrictionsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const profileFunction = useRestrictionsFunction();

  return (
    <RestrictionsContext.Provider value={profileFunction}>
      {children}
    </RestrictionsContext.Provider>
  );
};
