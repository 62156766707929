"use client";

import "@/i18n/config";

import { ReactNode, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import Zendesk from "@/components/ZenDesk";
import Analytics from "@/components/common/Analytics";
import Styles from "@/components/common/Styles";
import UserManagementModal from "@/components/common/UserManagementModal";
import { AuthContextProvider } from "@/contexts/common/AuthContextProvider";
import { NavContainerProvider } from "@/contexts/common/NavContainerProvider";
import { BRANDS } from "@/enums/brands";
import { AlertContextProvider } from "@/hooks/useAlert";
import { BannerContextProvider } from "@/hooks/useBanner";
import { ProfileContextProvider } from "@/hooks/useProfile";
import { RestrictionsContextProvider } from "@/hooks/useRestrictions";
import { GetTheme } from "@/utils/common/general";
import { GetBrand } from "@/utils/common/theme";
import { Auth0Provider } from "@auth0/auth0-react";
import { NextUIProvider } from "@nextui-org/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { PHProvider, PostHogPageview } from "./providers";
import ReleaseNoteInitialization from "./releaseNotesInitialization";

export default function RootLayout({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState("en");
  const [brand, setBrand] = useState<BRANDS>(BRANDS.GAIL);
  const [theme, setTheme] = useState<"light" | "dark">("light");

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    handleLanguageChange(navigator.language);
    setBrand(GetBrand(window.location.host));
    setTheme(GetTheme());
  }, []);

  useEffect(() => {
    const targetNode = document.documentElement;

    const observerCallback = (mutationsList: any) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-theme"
        ) {
          const currentTheme = targetNode.getAttribute("data-theme");
          if (currentTheme !== theme) {
            targetNode.setAttribute("data-theme", theme);
          }
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(targetNode, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [theme]);

  return (
    <html
      lang={language}
      data-brand={brand}
      data-theme={theme}
      style={{ backgroundColor: "#333354" }}
    >
      <head>
        <script
          src="https://kit.fontawesome.com/26f92a71e5.js"
          crossOrigin="anonymous"
          async
        ></script>
        <link rel="icon" href="/favicon.ico" />
      </head>
      <PHProvider>
        <body>
          <ReleaseNoteInitialization />
          <PostHogPageview />
          <SpeedInsights />
          <Analytics />
          <Styles />
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
            useRefreshTokens
            cacheLocation="localstorage"
            cookieDomain={process.env.NEXT_PUBLIC_AUTH0_COOKIE_DOMAIN!}
          >
            <AuthContextProvider>
              <NextUIProvider>
                <AlertContextProvider>
                  <ProfileContextProvider setLanguage={handleLanguageChange}>
                    <NavContainerProvider setShowUserManagement={setShow}>
                      <BannerContextProvider isAdmin={false}>
                        <RestrictionsContextProvider>
                          <Zendesk />
                          <UserManagementModal show={show} setShow={setShow} />
                          {children}
                        </RestrictionsContextProvider>
                      </BannerContextProvider>
                    </NavContainerProvider>
                  </ProfileContextProvider>
                </AlertContextProvider>
              </NextUIProvider>
            </AuthContextProvider>
          </Auth0Provider>
        </body>
      </PHProvider>
    </html>
  );
}
